<template>
  <div class="sign-up">
    <div class="flex-start-between">
      <div>
        <h2 class="fs-24 marginB26">{{$t('started')}}</h2>
        <p class="gray">{{$t('noCredit')}}</p>
        <p class="gray marginB33">{{$t('getTvu')}}</p>
      </div>
      <div
        class="sign-btn"
        @click="toSignIn">{{$t('signIn')}}</div>
    </div>
    <div v-if="!showPopup">
      <el-form
        :model="signForm"
        :rules="rules"
        ref="signForm">
        <el-form-item prop="userName">
          <el-input
            prefix-icon="iconfont icon-name fs-18"
            tabindex='1'
            v-model="signForm.userName"
            @keyup.enter.native="signUp"
            autocomplete="off"
            maxlength="50"
            :placeholder="$t('name')"></el-input>
        </el-form-item>
        <el-form-item
          v-if="!isCn"
          prop="account"
          class="account-form">
          <div class="account-icon cursor">
            <i
              v-show="signForm.msgType == 2"
              class="iconfont icon-phone fs-20 marginR15 primary"
              @click="changeAccountType(1)"></i>
            <i
              v-show="signForm.msgType == 1"
              class="iconfont icon-Mail fs-20 primary"
              @click="changeAccountType(2)"></i>
          </div>
          <div
            v-show="signForm.msgType == 2"
            class="telphone">
            <vue-tel-input
              class='telInput'
              v-model="phone"
              :placeholder="$t('emailOrPhone')"
              tabindex='2'
              @open="changeNation"
              @country-changed="changeCountry"
              @validate='validateNum'
              @blur="blurTel"
              @keyup.enter.native="signUp">
              <template slot="arrow-icon">
                <i class='el-icon-arrow-down'></i>
              </template>
            </vue-tel-input>
          </div>
          <div
            v-show="signForm.msgType == 2"
            class="exchange telphone errorTel"
            style="margin-top: 0">
            <div class="warning">
              <i class="el-icon-warning-outline"></i>
              <span class="warningTip">{{$t('enterTelTip')}}</span>
            </div>
          </div>
          <div
            class="emailArea exchange"
            v-show="signForm.msgType == 1">
            <el-input
              class="account-input account-email"
              tabindex='2'
              v-model="signForm.account"
              autocomplete="off"
              maxlength="50"
              @keyup.enter.native="signUp"
              @blur="blurAccount"
              :placeholder="$t('emailOrPhone')"></el-input>
            <div class="warning">
              <i class="el-icon-warning-outline"></i>
              <span class="warningTip">{{$t('emailTip')}}</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          v-if="isCn"
          prop="accountCn"
          class="account-form">
          <div class="account-icon-cn cursor">
            <i class="iconfont icon-phone fs-20 marginR10"></i>
            <span class="line-icon"></span>
            <i class="iconfont icon-Mail fs-20"></i>
          </div>
          <el-input
            class="account-input-cn"
            tabindex='2'
            v-model="signForm.accountCn"
            @keyup.enter.native="signUp"
            autocomplete="off"
            maxlength="50"
            :placeholder="$t('emailOrPhone')"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            prefix-icon="iconfont icon-Lock fs-22"
            tabindex='3'
            autocomplete="new-password"
            type="password"
            v-model="signForm.password"
            show-password
            @keyup.enter.native="signUp"
            :placeholder="$t('PsdPlaceholder')"></el-input>
        </el-form-item>
      </el-form>
      <div
        class="marginB15">
        <el-button
          v-repeat-click="{event:signUp}"
          class="sign-submit-btn"
          :disabled='isDisabled'
          :loading="isLoading">{{$t('startedNow')}}</el-button>
      </div>
      <div
        class="gray fs-13 sign-with"
        v-if="isPc">{{$t('signWith')}}</div>
      <other-login></other-login>
    </div>
    <div v-if="showPopup">
      <other-login></other-login>
      <div
        class="other-use"
        :class="showEmailLogin ? 'marginB12' : 'marginB48'"
        @click="showEmailLogin = !showEmailLogin">
        <span>{{$t('otherEmailUse')}}</span>
        <i
          class="iconfont"
          :class="showEmailLogin ? 'icon-arrow-up' : 'icon-arrow-down'"></i>
      </div>
      <el-form
        class="email-login"
        v-if="showEmailLogin"
        :model="signForm"
        :rules="rules"
        ref="signForm">
        <el-form-item prop="userName">
          <el-input
            prefix-icon="iconfont icon-name fs-18"
            tabindex='1'
            v-model="signForm.userName"
            @keyup.enter.native="signUp"
            autocomplete="off"
            maxlength="50"
            :placeholder="$t('name')"></el-input>
        </el-form-item>
        <el-form-item
          v-if="!isCn"
          prop="account"
          class="account-form">
          <div class="account-icon cursor">
            <i
              v-show="signForm.msgType == 2"
              class="iconfont icon-phone fs-20 marginR15 primary"
              @click="changeAccountType(1)"></i>
            <i
              v-show="signForm.msgType == 1"
              class="iconfont icon-Mail fs-20 primary"
              @click="changeAccountType(2)"></i>
          </div>
          <div
            v-show="signForm.msgType == 2"
            class="telphone">
            <vue-tel-input
              class='telInput'
              v-model="phone"
              :placeholder="$t('emailOrPhone')"
              tabindex='2'
              @open="changeNation"
              @country-changed="changeCountry"
              @validate='validateNum'
              @blur="blurTel"
              @keyup.enter.native="signUp">
              <template slot="arrow-icon">
                <i class='el-icon-arrow-down'></i>
              </template>
            </vue-tel-input>
          </div>
          <div
            v-show="signForm.msgType == 2"
            class="exchange telphone errorTel"
            style="margin-top: 0">
            <div class="warning">
              <i class="el-icon-warning-outline"></i>
              <span class="warningTip">{{$t('enterTelTip')}}</span>
            </div>
          </div>
          <div
            class="emailArea exchange"
            v-show="signForm.msgType == 1">
            <el-input
              class="account-input account-email"
              tabindex='2'
              v-model="signForm.account"
              autocomplete="off"
              maxlength="50"
              @keyup.enter.native="signUp"
              @blur="blurAccount"
              :placeholder="$t('emailOrPhone')"></el-input>
            <div class="warning">
              <i class="el-icon-warning-outline"></i>
              <span class="warningTip">{{$t('emailTip')}}</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          v-if="isCn"
          prop="accountCn"
          class="account-form">
          <div class="account-icon-cn cursor">
            <i class="iconfont icon-phone fs-20 marginR10"></i>
            <span class="line-icon"></span>
            <i class="iconfont icon-Mail fs-20"></i>
          </div>
          <el-input
            class="account-input-cn"
            tabindex='2'
            v-model="signForm.accountCn"
            @keyup.enter.native="signUp"
            autocomplete="off"
            maxlength="50"
            :placeholder="$t('emailOrPhone')"></el-input>
        </el-form-item>
        <el-form-item
          prop="password"
          style="margin-bottom: 0;">
          <el-input
            prefix-icon="iconfont icon-Lock fs-22"
            tabindex='3'
            autocomplete="new-password"
            type="password"
            v-model="signForm.password"
            show-password
            @keyup.enter.native="signUp"
            :placeholder="$t('PsdPlaceholder')"></el-input>
        </el-form-item>
      </el-form>
      <div
        v-if="showEmailLogin"
        class="marginB48 marginT40">
        <el-button
          v-repeat-click="{event:signUp}"
          class="sign-submit-btn"
          :disabled='isDisabled'
          :loading="isLoading">{{$t('startedNow')}}</el-button>
      </div>
    </div>
    <div class="fs-13 agree-info">
      <el-checkbox
        v-if="isShowPrivacyTypes"
        v-model="checked"></el-checkbox>
      {{$t('signUpType')}}{{$t('agreeInfo')}}
      <span
        style="white-space: nowrap;"
        class="border cursor agree-item"
        @click="jumpToPolicy(2)">{{$t('termsInfo')}}</span>
      {{$t('and')}}
      <span
        class="border cursor agree-item"
        @click="jumpToPolicy(1)">{{$t('policyInfo')}}</span>
    </div>
  </div>
</template>

<script>
import OtherLogin from '../components/OtherLogin'
import { getUrlParam } from '@/assets/js/utils'
import { mapState } from 'vuex'
import Vue from 'vue'
const sha512 = require('js-sha512')
const customValiate = {
  install (Vue) {
    Vue.prototype.$verify = {
      accountCnCheck: (rules, value, callback, tips1, tips2) => {
        const reg = /^([\w+.-]*[+-]*\.*)+@(\w-?)+(\.\w{2,})+$/
        const accountCn = value.replace(/\s*/g, '')
        if (value === '') {
          return callback(new Error(tips1))
        }
        if (reg.test(value) || (/^1[0-9]{10}$/.test(accountCn))) {
          callback()
        } else {
          callback(new Error(tips2))
        }
      }
    }
  }
}
Vue.use(customValiate)
export default {
  name: 'SignUpPage',
  data () {
    return {
      signForm: {
        userName: '',
        account: '',
        password: '',
        msgType: 1,
        accountCn: ''
      },
      checked: false,
      isShowPrivacyTypes: false,
      isDisabled: true,
      phone: '',
      verfyObj: {},
      isLoading: false,
      verifyCode: '',
      country: null,
      domainName: '',
      isCn: false,
      showPopup: false,
      showEmailLogin: false
    }
  },
  components: {
    OtherLogin
  },
  computed: {
    rules () {
      return {
        userName: [
          { required: true, message: this.$t('nameTip'), trigger: 'blur' }
        ],
        password: [
          { required: true, message: this.$t('passwordTip'), trigger: 'blur' },
          { pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、])[\da-zA-Z`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]{8,64}$/, message: this.$t('pswtip'), trigger: 'blur' }
        ],
        accountCn: [
          {
            required: true,
            validator: (rule, value, callback) => {
              this.$verify.accountCnCheck(rule, value, callback, this.$t('enterTip1Cn'), this.$t('enterTip2Cn'))
            },
            trigger: 'blur'
          }
        ]
      }
    },
    ...mapState({
      httpsFlag: 'httpsFlag',
      tagKeys: 'tagKeyList',
      location: 'location',
      isPc: 'isPc',
      hidePrivacyTypes: 'hidePrivacyTypes',
      popupServiceList: 'popupServiceList',
      clearCookiePathList: 'clearCookiePathList'
      // isShowMyID: 'isShowMyID',
      // key: 'key',
      // searchRegisterUrl: 'searchRegisterUrl'
    })
  },
  watch: {
    'signForm.userName': function (newVal) {
      if (this.isCn) {
        if (!newVal || !this.signForm.password || !this.signForm.accountCn) {
          this.isDisabled = true
        } else {
          this.isDisabled = false
        }
      } else {
        if (!newVal || !this.signForm.password || (!this.signForm.account && this.signForm.msgType == 1) || (!this.phone && this.signForm.msgType == 2)) {
          this.isDisabled = true
        } else {
          this.isDisabled = false
        }
      }
    },
    'signForm.password': function (newVal) {
      if (this.isCn) {
        if (!newVal || !this.signForm.userName || !this.signForm.accountCn) {
          this.isDisabled = true
        } else {
          this.isDisabled = false
        }
      } else {
        if (!newVal || !this.signForm.userName || (!this.signForm.account && this.signForm.msgType == 1) || (!this.phone && this.signForm.msgType == 2)) {
          this.isDisabled = true
        } else {
          this.isDisabled = false
        }
      }
    },
    'signForm.account': function (newVal) {
      if ($('.emailArea').hasClass('errorInput')) {
        $('.emailArea').removeClass('errorInput')
      }
      if (this.isCn) return
      if ((!newVal && this.signForm.msgType == 1) || !this.signForm.userName || !this.signForm.password) {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
    },
    phone: function (newVal) {
      if ($('.telphone').hasClass('errorInput')) {
        $('.telphone').removeClass('errorInput')
      }
      if (this.isCn) return
      if ((!newVal && this.signForm.msgType == 2) || !this.signForm.userName || !this.signForm.password) {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
    },
    'signForm.accountCn': function (newVal) {
      if (!this.isCn) return
      if (!newVal || !this.signForm.userName || !this.signForm.password) {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
    }
  },
  created () {
    this.showPopup = this.popupServiceList.includes(getUrlParam('popupService'))
    if (window.location.host.includes('cn')) {
      this.isCn = true
    }
    this.getDomainName()
  },
  mounted () {
    if (this.hidePrivacyTypes && this.hidePrivacyTypes.length < 1) {
      this.hidePrivacyTypes.push('tvuanywhere')
    }
    this.isShowPrivacyTypes = this.hidePrivacyTypes.includes(getUrlParam('type'))
  },
  methods: {
    jumpToPolicy (type) {
      const network = localStorage.getItem('lang') === 'zh' ? 'cn' : 'com'
      ga('send', 'event', 'newUserService-createAccount-goToPolicy', 'click')
      if (type == 1) {
        window.open(`https://www.tvunetworks.${network}/company-privacy-notice/`, '_blank')
      } else {
        window.open(`https://www.tvunetworks.${network}/company-terms-and-conditions/`, '_blank')
      }
    },
    changeAccountType (val) {
      this.signForm.msgType = val
      this.signForm.account = ''
      this.phone = ''
    },
    toSignIn () {
      const href = window.location.href
      const flag = href.includes('type=')
      if (flag) {
        // 移动端需要携带本来url上的参数
        const url = '/signIn?' + href.split('?')[1]
        this.$router.push({ path: url })
      } else {
        this.$router.push('/signIn')
      }
      // this.$router.push('/signIn')
    },
    changeNation () {
      this.blurTel()
      $('.vti__dropdown-item .hk').parent().remove()
      $('.vti__dropdown-item .tw').parent().remove()
      $('.vti__dropdown-item .mo').parent().remove()
      $('.vti__dropdown-item .cu').parent().remove()
      $('.vti__dropdown-item .sy').parent().remove()
      $('.vti__dropdown-item .kp').parent().remove()
    },
    blurTel () {
      if (this.country == '86') {
        const phone = this.phone.replace(/\s*/g, '')
        if (!(/^1[0-9]{10}$/.test(phone))) {
          $('.telphone').addClass('errorInput')
        } else {
          $('.telphone').removeClass('errorInput')
        }
      } else {
        if (this.phone && !this.verfyObj.valid) {
          $('.telphone').addClass('errorInput')
        } else {
          $('.telphone').removeClass('errorInput')
        }
      }
    },
    blurAccount () {
      const reg = /^([\w+.-]*[+-]*\.*)+@(\w-?)+(\.\w{2,})+$/
      if (!reg.test(this.signForm.account)) {
        $('.emailArea').addClass('errorInput')
      }
    },
    changeCountry (country) {
      // 被删除的国家和地方显示US
      if (country.iso2 == 'HK' || country.iso2 == 'TW' || country.iso2 == 'MO' || country.iso2 == 'CU' || country.iso2 == 'SY' || country.iso2 == 'KP') {
        country.dialCode = '1'
        const delClass = (country.iso2).toLowerCase()
        this.$nextTick(() => {
          $('.vti__selection .vti__flag').removeClass(delClass).addClass('us')
        })
      }
      this.country = country.dialCode
      ga('send', 'event', 'newUserService-register-changeCountry', 'click')
      if (Object.keys(this.verfyObj).length == 0) {
        return
      }
      this.blurTel()
    },
    validateNum (obj) {
      this.verfyObj = obj
    },
    getDomainName () {
      const localUrl = window.location.hostname
      const str1 = localUrl.split('.')[1]
      const str2 = localUrl.split('.')[2]

      this.domainName = `.${str1}.${str2}`
    },
    // 解析地址参数并返回obj参数
    getParams (paramStr) {
      const paramsObj = {}
      if (paramStr.includes('&')) {
        paramStr.split('&').forEach(item => {
          const str = item.split('=')
          paramsObj[str[0]] = str[1]
        })
      } else {
        const str = paramStr.split('=')
        paramsObj[str[0]] = str[1]
      }
      return paramsObj
    },
    // 清除tagkey
    clearTagKey () {
      const exp = new Date()
      exp.setTime(exp.getTime() - 1)
      this.tagKeys.forEach((item) => {
        window.document.cookie = `${item}=;path=/;domain=${this.domainName};expires=${exp.toGMTString()}`
      })
    },
    // 需要在登录或者注册之前清除cookie里的SID(不同path下的SID)
    clearPopupCookie () {
      const exp = new Date()
      exp.setTime(exp.getTime() - 1)
      this.clearCookiePathList.forEach((item) => {
        window.document.cookie = `SID=;path=${item};domain=${this.domainName};expires=${exp.toGMTString()};${this.httpsFlag ? 'Secure=true;' : ''}`
        window.document.cookie = `SID=;path=${item};domain=${this.domainName};expires=${exp.toGMTString()}`
      })
    },
    signUp () {
      if (this.isShowPrivacyTypes && !this.checked) {
        return this.$message.warning(this.$t('checkService'))
      }
      this.isDisabled = false
      if (!this.isCn && this.signForm.msgType == 2) {
        if (this.country == '86') {
          const phone = this.phone.replace(/\s*/g, '')
          if (!(/^1[0-9]{10}$/.test(phone))) {
            $('.telphone').addClass('errorInput')
            return
          }
        } else {
          if (!this.verfyObj.valid) {
            $('.telphone').addClass('errorInput')
            return
          }
        }
      }
      if (!this.isCn && this.signForm.msgType == 1) {
        const reg = /^([\w+.-]*[+-]*\.*)+@(\w-?)+(\.\w{2,})+$/
        if (!reg.test(this.signForm.account)) {
          $('.emailArea').addClass('errorInput')
          return
        }
      }
      this.clearPopupCookie()
      this.$refs.signForm.validate((valid) => {
        if (!valid) {
          return
        }
        // 移动端url
        const href = window.location.href
        let flag = false // false web;true anywhere
        let params = { ...this.signForm }
        params.password = sha512(this.signForm.password)

        if (href.includes('type=')) {
          // 移动端传参
          flag = true
          let val = []
          val = decodeURIComponent(href).split('?')
          const paramsObj = this.getParams(val[1])
          Object.assign(params, paramsObj)
        } else {
          flag = false
          params.origin = getUrlParam('serviceName')
          params.serverName = getUrlParam('serviceName')
          // tvu home邀请加入自己的组，用户注册时，需要携带inviteGroupId参数
          const keys = ['awsCustomerId', 'awsProductCode', 'awsAccountId', 'awsQuantity', 'inviteGroupId']
          keys.forEach(key => {
            let value = getUrlParam(key)
            if (value !== null) {
              params[key] = value
            }
          })
          if (this.location) {
            // TVU network登录不传
            params.url = this.location
          }
        }
        // 手机号登录并且是国外手机号的时候才需要传loginFlag
        if (this.country != '86' && this.signForm.msgType == 2) {
          params.loginFlag = 1
        }
        if (this.signForm.msgType == 2) {
          params.account = this.phone.replace(/\s*/g, '')
        }
        if (this.isCn) {
          // 中国区传参
          if (/^([\w+.-]*[+-]*\.*)+@(\w-?)+(\.\w{2,})+$/.test(params.accountCn)) {
            // 邮箱
            this.signForm.msgType = 1
            params.msgType = 1
          }
          if ((/^1[0-9]{10}$/.test(params.accountCn.replace(/\s*/g, '')))) {
            // 手机号
            this.signForm.msgType = 2
            params.msgType = 2
            this.country = '86'
          }
          params.account = this.signForm.msgType == 2 ? params.accountCn.replace(/\s*/g, '') : params.accountCn
          delete params.accountCn
        }
        delete params.accountCn
        this.isLoading = true
        this.$axios.post(
          '/login-service/login/register', params).then((res) => {
          if (res.data.errorCode === '0x0') {
            if (res.data.result) {
              const data = res.data.result
              // 登录成功存储sid
              // this.remenberPsw()
              localStorage.setItem('email', params.account)
              localStorage.setItem('firstName', data.firstName)
              localStorage.setItem('userName', data.userName)
              localStorage.setItem('session', data.session)
              localStorage.setItem('userservice', data.userservice)
              window.userservice = data.userservice
              // media hub弹窗内登录成功之后，先将tagkey清除，再存储
              // if (this.showPopup) {
              //   this.clearTagKey()
              // }
              // 登录成功之后，先将tagkey清除，再存储
              this.clearTagKey()
              if (this.domainName) {
                // window.document.cookie = `SID=${data.session};path=/;domain=${this.domainName};${this.httpsFlag ? 'secure' : ''}`
                if (data.tagList && data.tagList.length > 0 && this.$store.state.saveTagCookie) {
                  data.tagList.forEach(itm => {
                    window.document.cookie = `${itm.tagKey}=${itm.tagValue};path=/;domain=${this.domainName};${this.httpsFlag ? 'secure' : ''}`
                  })
                }
              }
              // 此时设置sid有效
              this.$store.commit('changesValidSid', true)
              // let flag = false
              if (flag) {
                // 移动调用方法
                // window.document.cookie = `SID=${data.session};path=/;domain=${this.domainName};${this.httpsFlag ? 'secure' : ''}`
                if (data.tagList && data.tagList.length > 0 && this.$store.state.saveTagCookie) {
                  data.tagList.forEach(itm => {
                    window.document.cookie = `${itm.tagKey}=${itm.tagValue};path=/;domain=${this.domainName};${this.httpsFlag ? 'secure' : ''}`
                  })
                }
                // data.tvutag?window.document.cookie = "tvutag=" +data.tvutag+ ";path=/;domain=" + this.domainName + ";":"";
                const u = navigator.userAgent
                const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端

                if (isAndroid) { // 安卓机型
                  // getUserInfo(data.userId, data.session);
                  Android.getUserInfo(JSON.stringify({
                    userId: data.userId,
                    session: data.session,
                    firstName: data.firstName,
                    email: data.email,
                    loginType: '' // 只在第三方登录的时候给app端传递这个参数的值，账号密码登录传递空字符串
                  }))
                } else { // ios机型
                  window.webkit.messageHandlers.getUserInfo.postMessage(JSON.stringify({
                    userId: data.userId,
                    session: data.session,
                    firstName: data.firstName,
                    email: data.email,
                    loginType: '' // 只在第三方登录的时候给app端传递这个参数的值，账号密码登录传递空字符串
                  }))
                  // 通知gridlink登录成功
                  window.webkit.messageHandlers.hideNavIfLogin.postMessage(JSON.stringify({}))
                }
              } else {
                // web跳转
                if (data.loginWelcome == '1') {
                  this.$router.push('/welcome')
                } else {
                  window.top.location.href = data.redirectUrl
                }
              }
            } else {
              if (flag) {
                // 移动端需要携带本来url上的参数
                const url = '/signIn?' + href.split('?')[1]
                this.$router.push({ path: url })
              } else {
                this.$router.push('/signIn')
              }
            }
          } else if (res.data.errorCode === '80943101') {
            // 用户注册但未验证，跳转到验证码验证页面
            let userInfo = {
              account: params.account,
              msgType: this.signForm.msgType,
              country: this.signForm.msgType == 2 ? this.country : null
            }
            this.$store.commit('changesUserInfo', userInfo)

            const href = window.location.href
            const flag = href.includes('type=')
            if (flag) {
              // 移动端需要携带本来url上的参数
              const url = '/verification?verifyFlag=1&' + href.split('?')[1]
              this.$router.push({ path: url })
            } else {
              this.$router.push({ path: '/verification', query: { verifyFlag: 1 } })
            }
            // this.$router.push({ path: '/verification', query: { verifyFlag: 1 } })
          } else if (res.data.errorCode === '80943102') {
            this.$message({
              message: this.signForm.msgType == 1 ? this.$t('error80943102') : this.$t('errorTel80943102'),
              type: 'error',
              duration: 3000
            })
          } else if (res.data.errorCode === '80943008') {
            this.$message({
              message: this.$t('error80943008'),
              type: 'error',
              duration: 3000
            })
          } else if (res.data.errorCode === '81000002') {
            this.$message({
              message: this.$t('error81000002'),
              type: 'error',
              duration: 3000
            })
          } else {
            this.$message({
              message: res.data.errorInfo,
              type: 'error',
              duration: 3000
            })
          }
          this.isLoading = false
        }).catch(() => {
          this.isLoading = false
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.otherLogin {
  margin: 36px 48px 0;
}

.otherLogin img {
  width: 86px;
}

.sign-with {
  margin: 40px 0 27px;
}

.account-form {
  position: relative;
  .account-icon {
    position: absolute;
    z-index: 9;
    color: #C0C4CC;
    left: 12px;
    top: 4px;
  }
}

.vue-tel-input {
  border: 1px solid #979797;
  border-radius: 8px;
  padding: 15px 20px 20px 26px;
  height: 48px;
  line-height: 48px;
}

.vue-tel-input:focus-within {
  border: 1px solid #47A31F;
  box-shadow: none;
}

/deep/.vti__dropdown-list {
  z-index: 9;
  left: -26px;
  padding: 10px 0;
  -webkit-overflow-scrolling: touch;
}

.pc-sign-wrap /deep/.vti__dropdown-list {
  width: 358px;
}

.mobile-sign-wrap /deep/.vti__dropdown-list {
  width: 270px;
}
/*!**定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*!*/
/deep/.vti__dropdown-list::-webkit-scrollbar {
  width: 6px;
  background-color: cadetblue;
}

/*!*定义滚动条轨道 内阴影+圆角*!*/
/deep/.vti__dropdown-list::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #fff;
}

/*!*定义滑块 内阴影+圆角*!*/
/deep/.vti__dropdown-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #aaa;
}

/deep/.vti__country-code {
  font-size: 12px;
}

/deep/.vti__dropdown-item {
  height: 30px;
  line-height: 30px;
}

/deep/.vti__input {
  height: 15px;
  padding-left: 0;
}

/deep/.vti__dropdown ul li {
  display: flex;
  align-items: center;
}

/deep/.vti__dropdown ul li strong {
  display: block;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.errorTel, .warning {
  margin-top: 5px;
  line-height: 20px;
}

.exchange .warning {
  display: none;
  .warningTip, i {
    color: #d93025;
  }
}

.errorInput {
  /deep/.el-input__inner, /deep/.vue-tel-input {
    border: 1px solid #d93025
  }
}

.errorInput .warning {
  display: block !important;
}

.warningTip {
  margin-left: 5px;
}

/deep/.account-email .el-input__inner {
  padding-left: 40px;
}

.account-form {
  position: relative;
  .line-icon {
    position: absolute;
    height: 21px;
    border-right: solid .5px #979797;
    display: inline-block;
    top: 9px;
    left: 25px;
    transform: rotate(25deg);
  }
  .account-icon-cn {
    position: absolute;
    z-index: 9;
    color: #C0C4CC;
    left: 12px;
    top: 4px;
  }
  .account-input-cn /deep/.el-input__inner {
    padding-left: 72px;
  }
}

.other-use {
  display: block;
  cursor: pointer;
  color: #000;
  margin-top: 14px;
  font-size: 13px;
  i {
    vertical-align: middle;
    margin-left: 8px;
  }
}

.email-login {
  border-radius: 6px;
  background: #FAFAFA;
  padding: 16px 12px;
}
</style>
